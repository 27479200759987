import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { addWishlist } from "../../services/userService";

const ProductCardSample = ({
  imgSrc,
  hoverImageSrc,
  title,
  price,
  productUrl,
  wishlistUrl,
  data,
}) => {
  const navigate = useNavigate();
  const isLogin = useSelector((state) => state?.auth?.isLogin);
  const [isSolid, setIsSolid] = useState(false);
  useEffect(() => {
    setIsSolid(data?.isWishlisted);
  }, [data]);

  const onHandleWishlist = () => {
    if (!isLogin) {
      navigate("/login");
    } else {
      addWishlist({
        id: data?.variantId,
      }).then((res) => {
        if (res?.status == 200) {
          setIsSolid(!isSolid);
        }
      });
    }
  };

  const limitTextLength = (text, limit) => {
    if (text.length > limit) {
      return text.slice(0, limit) + "...";
    }
    return text;
  };

  const onDetailsChange = (data) => {
    if (data?.type === "variant-product") {
      const query = {};

      data?.variants?.attributes?.forEach((item) => {
        if (item?.items) {
          query[item?.attribute?.name] = Array.isArray(item?.items)
            ? item?.items[0]?._id
            : item?.items?._id;
        }
      });

      const searchParams = new URLSearchParams(query).toString();

      navigate(`/products-details/${data?.slug}?${searchParams}`);
    } else {
      navigate(`/products-details/${data?.slug}`);
    }
  };

  return (
    <div className="bg-white border border-gray-200 rounded-lg shadow-md overflow-hidden group flex flex-col h-full">
      <div className="relative">
        <div onClick={() => onDetailsChange(data)} className="block">
          <img
            src={data?.cover?.url}
            alt={title}
            className="w-full h-64 object-cover transition-transform transform group-hover:scale-105"
          />
        </div>
        {/* <Link
          to={wishlistUrl}
          className="absolute top-3 right-3 text-red-500 text-xl"
        >
          <i className="fa-regular fa-heart" aria-hidden="true"></i>
        </Link> */}
        <button
          className="absolute w-5 h-5 md:w-8 md:h-8 rounded-full bg-white overflow-hidden right-[12px] md:right-[22px] top-3 md:top-6 z-[2] text-xs md:text-lg"
          onClick={onHandleWishlist}
        >
          <i
            className={isSolid ? "fa-solid fa-heart" : "fa-regular fa-heart"}
            style={{ color: isSolid ? "#f00" : "#000" }}
          ></i>
        </button>
      </div>
      <div className="p-4 flex flex-col flex-grow">
        <h3 className="text-lg font-semibold text-gray-900 mb-2 truncate">
          <div
            onClick={() => onDetailsChange(data)}
            className="hover:underline block"
          >
            {limitTextLength(data?.name || title, 15)}
          </div>
        </h3>
        <div className="mt-auto">
          <div className="md:flex items-center md:space-x-2 md:space-y-0 space-y-1">
            <div className="text-sm text-gray-500 line-through">
              Regular Price: Rs.{data?.regularPrice || 0}
            </div>
            <div className="md:text-lg text-sm font-bold text-blue-500">
              Sale Price: Rs.{data?.salePrice || 0}
            </div>
          </div>
          <div className="mt-4">
            <div
              onClick={() => onDetailsChange(data)}
              className="bg-black text-white text-sm font-medium py-2 md:px-4 px-2 rounded hover:bg-victoria transition-colors duration-200 ease-in-out w-full inline-block text-center"
            >
              + Add to cart
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCardSample;
