import React, { useCallback, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import banner from "../../assets/img/bg/banner2.jpg";
import slide1 from "../../assets/img/product/product5.jpg";
import ProductCardSample from "../product/ProductCardSample";
import { productListData } from "../../services/productservice";
import { toast } from "react-toastify";

const TopSellingItems = () => {
  const [list, setList] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });
  //  Product list >>>>>>>>>
  const loadList = useCallback(() => {
    setList((pre) => ({
      ...pre,
      data: [],
      loading: false,
    }));
    productListData({ limit: 30 }).then((res) => {
      if (res && res?.status === 200) {
        setList({
          loading: false,
          data: res?.docs,
          pageCount: res?.docs?.metadata?.totalPages,
          totalItem: res?.docs?.metadata?.totalDocs,
        });
      } else {
        setList((pre) => ({ ...pre, data: [], loading: false }));
        toast.error(res?.message);
      }
    });
  }, []);

  useEffect(() => {
    loadList();
  }, [loadList]);

  const topSellingProductData = list && list?.data.length > 0 ? list?.data : [];

  return (
    <section className="py-10">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap items-center">
          <div className="w-full lg:w-5/12 mb-12 lg:mb-0">
            <div className="relative p-5">
              <a href="product-listing.html">
                <img src={banner} alt="Banner" className="w-full h-auto" />
              </a>
            </div>
          </div>

          <div className="w-full lg:w-7/12">
            <div className="section_title mb-8">
              <h2 className="text-3xl font-bold">Top Selling Items</h2>
              <p className="text-lg">
                Contemporary, minimal and modern designs embody the Lavish Alice
                handwriting
              </p>
            </div>
            <div className="relative">
              <Swiper
                modules={[Navigation]}
                spaceBetween={20}
                slidesPerView={1}
                pagination={{ clickable: true }}
                navigation={true}
                breakpoints={{
                  320: { slidesPerView: 2 },
                  640: { slidesPerView: 2 },
                  768: { slidesPerView: 3 },
                  1024: { slidesPerView: 3 },
                }}
                className="mb-8 !h-full flex"
              >
                {topSellingProductData?.map((product) => (
                  <SwiperSlide
                    key={product._id}
                    className="flex justify-center"
                  >
                    <ProductCardSample
                      imgSrc={slide1}
                      data={product}
                      hoverImageSrc={banner}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              <Swiper
                modules={[Navigation]}
                spaceBetween={20}
                slidesPerView={1}
                pagination={{ clickable: true }}
                navigation={true}
                breakpoints={{
                  320: { slidesPerView: 2 },
                  640: { slidesPerView: 2 },
                  768: { slidesPerView: 3 },
                  1024: { slidesPerView: 3 },
                }}
                className=""
              >
                {topSellingProductData?.map((product) => (
                  <SwiperSlide
                    key={product._id}
                    className="flex justify-center"
                  >
                    <ProductCardSample
                      imgSrc={banner}
                      data={product}
                      hoverImageSrc={slide1}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopSellingItems;
