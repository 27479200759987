import React, { useState, useEffect } from "react";
import Image from "../components/elements/Image";
import Button from "../components/form/Button";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../src/assets/img/unicorn.svg";
import { classNames } from "../helpers/classNames";

const DefaultHeader = ({ user, totalCartData }) => {
  const [isOpen, setOpen] = useState(false);
  const isLogin = useSelector((state) => state?.auth?.isLogin);
  const [menuToggle, setMenuToggle] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isListOpen, setIsListOpen] = useState(false);
  const socialMedia = [
    {
      _id: 1,
      name: "Facebook",
      icon: "fa-brands fa-square-facebook",
      url: "https://www.facebook.com/",
    },
    {
      _id: 2,
      name: "Whatsapp",
      url: `https://wa.me/${8902070013}`,
      icon: "fa-brands fa-whatsapp",
    },
    {
      _id: 3,
      name: "Whatsapp",
      url: `https://wa.me/${8902070013}`,
      icon: "fa-brands fa-instagram",
    },
    // {
    //   _id: 3,
    //   name: "Photos",
    //   url: "https://g.co/kgs/8cXd3w8",
    //   icon: "fa-solid fa-image",
    // },
  ];

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const menuData = [
    { _id: 1, name: "Home", link: "/" },
    { _id: 2, name: "About Us", link: "/" },
    { _id: 3, name: "Unicorn Special", link: "/products" },
    { _id: 4, name: "overized t shirt", link: "/products" },
    { _id: 5, name: "Anime", link: "/products" },
    { _id: 6, name: "Full Sleeve", link: "/products" },
    {
      _id: 7,
      name: "Special Occasion Gift Items",
      link: "/products",
    },
    // { _id: 8, name: "Blog", link: "/blog" },
    { _id: 9, name: "Contact Us", link: "/contact-us" },
  ];

  const onPageHandler = (link) => {
    navigate(link);
    setMenuToggle(false);
  };

  return (
    <>
      <div className="bg-org-black py-2 relative z-30">
        <div className="w-full px-4 sm:px-0 sm:max-w-xl md:max-w-6xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto flex items-center">
          <div className="grid grid-cols-12 gap-1 md:gap-3 xl:gap-2 w-full items-center">
            <div className="col-span-12 md:col-span-9 space-x-1 md:space-x-5 flex justify-center xl:justify-start md:flex-row flex-col md:items-start items-center">
              <a
                href="tel:8902070013"
                className="text-white text-xs md:text-md lg:text-md md:border-r md:border-white pr-4 flex justify-start items-center"
              >
                <i className="fa-sharp fa-solid fa-phone-volume mr-2"></i>{" "}
                <span className="hidden md:block">Call Us: </span> 8902070013(
                Mon - Sat 11.00AM to 8PM )
              </a>
              <a
                href="inforunicorn@gmail.com"
                className="text-white text-xs md:text-md lg:text-md flex justify-start items-center mdLpl-0 pl-4"
              >
                <i className="fa-solid fa-envelope mr-2"></i>{" "}
                <span className="hidden md:block">Email Us: </span>
                inforunicorn@gmail.com
              </a>
            </div>
            <div className="col-span-12 md:col-span-3">
              <ul className="flex justify-center xl:justify-end space-x-2 mt-2 md:mt-0">
                {socialMedia.map((item) => (
                  <li
                    key={item._id}
                    className="flex bg-victoria w-7 h-7 text-center rounded-full border border-white hover:bg-carnation-800 transition-all duration-200"
                  >
                    <a
                      href={item.url}
                      target="_blank"
                      className="text-xs text-white w-full pt-1.5"
                    >
                      <i
                        className={classNames(" cursor-pointer", item.icon)}
                      ></i>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <header
        className={`sticky z-30 top-0 w-full flex items-center shadow ${
          isScrolled
            ? "bg-black xl:text-white text-black h-16"
            : "bg-slate-100 text-slate-950 xl:h-28 h-16 transition-all duration-75"
        }`}
        style={{
          height: isScrolled ? "" : "", // Adjusting height for the transition
          transition: "all 0.3s ease-in-out", // Adding smooth transition
        }}
      >
        <div className="w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-6xl xl:max-w-7xl 2xl:max-w-8xl 3xl:max-w-9xl mx-auto flex items-center">
          <div className="flex justify-between w-full items-center xl:px-0 md:px-5 px-0">
            <div className="flex justify-between items-center gap-5">
              <div
                className={` ${
                  isScrolled
                    ? "h-12"
                    : " 2xl:h-20 h-16 transition-all duration-75 flex justify-center"
                }`}
              >
                <Link
                  to={"/"}
                  className="block w-32 xl:w-full h-auto xl:h-full"
                >
                  <Image
                    src={logo}
                    alt={"Logo"}
                    width={"100%"}
                    height={"100%"}
                    effect={"blur"}
                    classes={"object-contain object-left"}
                  />
                </Link>
              </div>
            </div>

            {/* <div
            // className={`sticky z-30 top-0 w-full flex items-center h-16 shadow ${
            //   isScrolled
            //     ? "bg-org-theme text-white"
            //     : "bg-slate-100 text-slate-950"
            // }`}
            > */}
            {/* <div className="w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto flex items-center justify-center"> */}
            <div className="flex md:gap-14 gap-0">
              <div className="hidden xl:flex justify-center items-center space-y-4 xl:space-y-0 xl:space-x-10">
                <ul className="flex 3xl:space-x-10 space-x-5 justify-center">
                  {menuData.map((link) => (
                    <li key={link._id}>
                      <Link
                        to={link.link}
                        className="cursor-pointer text-sm transition duration-200 hover:text-org-theme"
                      >
                        {link.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              {/* </div> */}
              {/* </div> */}
              <div className="flex items-center">
                {isLogin ? (
                  <>
                    <div className="relative">
                      <div className="text-org-theme">
                        <Button
                          buttonClasses={
                            "3xl:!px-3 md:!px-2 !px-1 !h-10 md:!text-3xl text-lg !text-victoria !bg-transparent"
                          }
                          buttonHasLink={true}
                          buttonIcon={"fa-light fa-heart"}
                          buttonIconPosition={"left"}
                          buttonEffect={"filled"}
                          buttonLink={"/wishlist"}
                        />
                      </div>
                    </div>

                    <div
                      className="relative"
                      onMouseEnter={() => setIsListOpen(true)}
                      onMouseLeave={() => setIsListOpen(false)}
                    >
                      <div className="text-org-theme">
                        <Button
                          buttonClasses={
                            "3xl:!px-3 md:!px-1 !px-1 !h-10 md:!text-3xl text-lg !text-victoria !bg-transparent"
                          }
                          buttonHasLink={true}
                          buttonIcon={"fa-light fa-bag-shopping"}
                          buttonIconPosition={"left"}
                          buttonEffect={"filled"}
                          buttonLink={"/cart"}
                        />
                      </div>
                      <div className="bg-victoria absolute right-2 xl:right-2 top-0 xl:-top-1 rounded-full w-4 xl:w-5 h-4 xl:h-5 text-[8px] xl:text-[10px] flex items-center justify-center text-white">
                        {totalCartData?.totalCartItem
                          ? totalCartData.totalCartItem < 100
                            ? totalCartData.totalCartItem
                            : "99+"
                          : 0}
                      </div>

                      {/* <div className="absolute md:right-0 -right-12 top-full md:w-[400px] w-[300px]">
              <CartHover/>
            </div> */}
                    </div>

                    <div className="relative">
                      <div className="text-org-theme">
                        <Button
                          buttonClasses={
                            "3xl:!px-3 md:!px-2 !px-1 !h-10 md:!text-3xl text-lg !text-victoria !bg-transparent"
                          }
                          buttonHasLink={true}
                          buttonIcon={"fa-solid fa-user"}
                          buttonIconPosition={"left"}
                          buttonEffect={"filled"}
                          buttonLink={"/profile"}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="relative">
                      <div className="text-org-theme">
                        <Button
                          buttonClasses={
                            "!md:px-5 !px-1 !h-10 md:!text-3xl text-lg !bg-transparent !text-victoria !hover:bg-transparent"
                          }
                          buttonHasLink={true}
                          buttonIcon={"fa fa-sign-in"}
                          buttonIconPosition={"left"}
                          buttonEffect={"filled"}
                          buttonLink={"/login"}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="xl:hidden">
                <Button
                  buttonClasses={
                    "!bg-transparent !px-0 w-12 !text-org-theme md:!text-2xl !text-lg"
                  }
                  buttonHasLink={false}
                  buttonFunction={() => setMenuToggle(!menuToggle)}
                  buttonIcon={"fa-solid fa-bars"}
                  buttonIconPosition={"left"}
                />
              </div>
            </div>
            {/* Mobile Menu */}
            {menuToggle && (
              <>
                {/* Backdrop */}
                <div
                  className={`fixed inset-0 z-20 transition-opacity duration-300 ${
                    isScrolled ? "bg-black/50 backdrop-blur-sm z-[-1]" : ""
                  }`}
                  onClick={() => setMenuToggle(false)} // Close menu when clicking outside
                ></div>
                <div
                  className={`absolute top-full right-0 w-full bg-white shadow-md xl:hidden md:!w-1/2 !w-10/12 h-screen ${
                    isScrolled ? "!black" : ""
                  }`}
                >
                  <ul className="flex flex-col space-y-2 p-4">
                    {menuData.map((link) => (
                      <li key={link._id}>
                        <Link
                          to={link.link}
                          className="block text-sm transition duration-200 hover:text-org-theme py-2"
                          onClick={() => setMenuToggle(false)}
                        >
                          {link.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            )}
          </div>
        </div>
      </header>
      {/* <header
        className={`sticky z-30 top-0 w-full flex items-center h-16 shadow ${
          isScrolled ? "bg-org-theme text-white" : "bg-slate-100 text-slate-950"
        }`}
      >
        <div className="w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto flex items-center justify-center">
          <div className="block xl:flex justify-center items-center space-y-4 xl:space-y-0 xl:space-x-10">
            <ul className="flex space-x-10 justify-center">
              {menuData.map((link) => (
                <li key={link._id}>
                  <Link
                    to={link.link}
                    className="cursor-pointer text-sm transition duration-200 hover:text-org-theme"
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </header> */}
    </>
  );
};

export default DefaultHeader;
